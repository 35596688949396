import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })
export class CommonService {
  constructor(private http: HttpClient,
              private router: Router,
              private dataService: DataService
               ) { }


  downloadPdf(invoiceNo: string, type: 'invoice' | 'receipt'): void {
    if (!invoiceNo) {
      return;
    }

    this.dataService.downloadPaymentPdf(invoiceNo, type).subscribe((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const filename = `${invoiceNo}_${type}.pdf`;
        this.triggerDownload(file, filename);
    });
  }

  downloadPdfFile(content: HTMLElement, filename: string): void {
    const cloned = content.cloneNode(true) as HTMLElement;
    cloned.querySelector('.sidebar')?.remove();
    cloned.querySelector('#downloadBtn')?.remove();
    cloned.querySelector('.main-content')?.classList.replace('col-lg-8', 'col-12');
    this.generatePdfFromHtml(cloned, filename);
  }

  goToPaymentSuccessPage(): void {
    // this.progBar.SetStep(PAYMENT_STEP.PAYMENT_COMPLETE);
    this.router.navigate(['payment-success']);
  }

  goToNotFoundPage(): void {
    this.router.navigate(['/notfound']);
  }

  generatePdfFromHtml(content: HTMLElement, filename: string): void {
    const html2Pdf = window['html2pdf'];
    if (html2Pdf) {
      const opts = {
        margin: 1,
        filename: `${filename}.pdf`,
        pagebreak: {mode: 'avoid-all'}
      };

      html2Pdf().set(opts).from(content).save();
    }
  }
  private triggerDownload(file: Blob, filename: string): void {
    // IE10+
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      // Others
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
}
