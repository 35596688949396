import { OnDestroy, Injectable } from '@angular/core';
import { Subject, EMPTY, Observable } from 'rxjs';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { first } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class RecaptchaCheckService implements OnDestroy {
  isHuman = new Subject<boolean>();
  constructor() {}

  // onError() {
  // }

  onSuccess() {
    this.isHuman.next(true);
  }

  execute(component: InvisibleReCaptchaComponent ) {
    if (!component) {
      return EMPTY;
    }

    if (component.getResponse()) {
      this.isHuman.next(true);
    } else {
      component.execute();
    }
    return this.isHuman.pipe(first());
  }

  ngOnDestroy(): void {
    this.isHuman.complete();
  }
}
