import { Component, Input, OnInit } from '@angular/core';
import { StepperService } from '@shared/services/stepper.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  constructor(public stepperService: StepperService) { }

  ngOnInit(): void {
  }
  // get steps(): any[] {
  //   return this.stepperService.steps;
  // }
}
