<div class="row">
  <div class="col">
    <h5>{{'paymentStatus.status'| translate}}</h5>
    <h5>{{ invoice | mask: '0000 0000 0000 0000'}}</h5>
  </div>
  <div class="col">
    <h3  class="p-3 text-center color-red status bg-color-red">
      <i aria-hidden="true" class="fa fa-times-circle color-red"></i>
      {{'paymentStatus.notFound'| translate}}
    </h3>
  </div>
</div>
