<div
  class="
    steps
    d-flex
    flex-column
    flex-md-row
    mb-5
  "
>
  <ng-container *ngIf="stepperService.steps && stepperService.steps?.length">
    <div class="step completed "
         [ngClass]="{ 'completed': stepperService.steps.length == 1 || i <= stepperService.currentStepIndex, 'rounded': !stepperService.currentStepIndex, 'last': i == stepperService.currentStepIndex }"
         *ngFor="let step of stepperService.steps; let i = index;">
      <div class="step-icon-wrap">
        <div class="step-icon">{{i + 1}}</div>
        <div class="step-title"
             [ngClass]="{'text-primary': i == stepperService.currentStepIndex || i == 0 && !stepperService.currentStepIndex }">
          <a [ngClass]="{'disabled': stepperService.currentStepIndex < i , 'text-primary': i == stepperService.currentStepIndex || i === 0 || i < stepperService.currentStepIndex  }"
             [routerLink]="stepperService.currentStepIndex > i ? step.link : null">{{step.title}}</a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
