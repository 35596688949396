import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})

export class CustomDatePipe implements PipeTransform {
  transform(value: any): string | null {
    const formattedDate = new Date(value);
    if (!this.isValidDate(formattedDate)) {
      return 'n/a';
    }
    return formattedDate.toLocaleString();
  }

  isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date as any);
  }
}
