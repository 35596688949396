import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
@Injectable()
export class LoaderService {
  private loader = new BehaviorSubject<boolean>(false);

  loaderStatus$ = this.loader.asObservable();

  constructor() {

  }


  public showLoader(): void {
    this.loader.next(true);
  }

  public hideLoader(): void {
    this.loader.next(false);
  }
}
