import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements OnInit {
  @Output()
  changed: EventEmitter<File[]> = new EventEmitter<File[]>();
  multiSelected: File[] = [];

  files = {
    chooseFiles: 'Choose files',
    noFilesSelected: 'No files selected',
    addMoreFiles: 'You can add more files',
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  public onFileSelected(event): void {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.multiSelected.push(files.item(i));
    }
    this.changed.emit(this.multiSelected);
  }

  public removeFile(index): void {
    this.multiSelected.splice(index, 1);
    this.changed.emit(this.multiSelected);
  }
}
