import { format } from 'date-fns';

export const scrollToFooter = (): void => {
  window.scrollTo(0, document?.body?.scrollHeight);
};

export const getTotalAmountWithCommission = (totalAmountDue = 0, commission = 0) => {
  return totalAmountDue + commission;
};

export const scrollInto = (id: string, activeLink?: string): string => {
  const el = document.getElementById(id) as HTMLElement;
  activeLink = id;
  el.scrollIntoView({behavior: 'smooth'});
  return activeLink;
};

export const getTodayDate = () => {
  return format(new Date(), 'dd/MM/yyyy');
};
