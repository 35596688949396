import { Status } from "./status.enum";

interface Customer {
    customerType?: string;
    id: string;
    name: string;
}

interface Currency {
    code: string;
    name: string;
    number: string
}

interface ReceiptProperty {
    displayName: string;
    editable: boolean;
    isVisible: boolean;
    name: string;
    required: boolean;
    type: string;
    value?: string;
}

interface ReceiptService {
    amountDue: number;
    properties: ReceiptProperty[];
    serviceName: string;
}

export interface ReceiptModel {
    amount: number;
    clientService: string;
    commission: number;
    created: string;
    currency: Currency;
    customer: Customer;
    invoiceNumber: string;
    paymentProcessor: string;
    paymentProcessorDisplayName?: string;
    serviceProvider: string;
    serviceProviderCode: string;
    services: ReceiptService[];
    status: Status;
    totalAmount: number;
    transactionDate: string;
    transactionNumber: string;
}