import { Component } from '@angular/core';
import { scrollToFooter } from '@shared/utils/utils';
import { PROJECT_TITLE } from '@shared/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public eServicesUrl = 'https://eservices.gov.zm';
  public isCollapsed = true;
  public scrollToFooter = scrollToFooter;
  public readonly PROJECT_TITLE = PROJECT_TITLE;
}
