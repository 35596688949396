import { Status } from './status.enum';
import { Service } from './service.model';

export class Invoice {
  clientService?: string;
  number?: string;
  externalReference?: string;
  customer: any;
  serviceName?: string;
  services?: Service[];
  mainAuthority?: string;
  serviceProvider?: string;
  currency?: string;
  totalAmountDue = 0;
  expires?: Date;
  created?: Date;
  completed?: Date;
  lastTransactionDate?: Date;
  status?: Status;
  returnUrl?: string;
  paymentProcessor?: string;
  amount?: number;
  commission?: number;
}
