<div class="d-flex align-items-center upload__form pl-4">
  <input (change)="onFileSelected($event)" class="d-none" type="file" id="uploadInput" multiple>
  <span class="upload__plus">+</span>
  <button class="border-right pr-4 upload__choose-btn">
    {{files.chooseFiles}}
  </button>
  <div>
    <label class="pl-4 m-0" for="uploadInput">
      <span *ngIf="multiSelected.length === 0"> {{files.noFilesSelected}} </span>
      <span *ngIf="multiSelected.length > 1"> {{files.addMoreFiles}} </span>
    </label>
  </div>
</div>
<div *ngIf="!!multiSelected.length" class="d-flex flex-column pl-4 pt-2 border-top upload__form">
  <label *ngFor="let selected of multiSelected; let i = index;" class="d-flex justify-content-between pr-4">
    {{selected.name}}
    <button (click)="removeFile(i)" class="upload__remove-btn">x</button>
  </label>
</div>
