import { Injectable } from '@angular/core';

type step = {title: string, link: string[]| null, id: string};

@Injectable({providedIn: 'root'})
export class StepperService {
  currentStepIndex = 0;
  constructor() { }
  steps: step[] = [];
  setSteps(steps: step[]): void {
    this.steps = steps;
  }

  setCurrentStep(id: string): void {
    this.currentStepIndex =  this.steps.findIndex(s => s.id === id);
  }

  resetSteps(): void {
    this.currentStepIndex = 0;
  }
}
