import { Component } from '@angular/core';
import { AppConfig } from '@shared/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year: number;
  version = AppConfig.settings?.appVersion ? `(${AppConfig.settings?.appVersion})` : '';

  usefulLinks = [
    {
      title: 'menuItems.about',
      href: 'about'
    },
    {
      title: 'menuItems.termsAndConditions',
      href: 'terms-and-conditions'
    },
    {
      title: 'menuItems.faq',
      href: 'faq'
    },
    {
      title: 'menuItems.privacyPolicy',
      href: 'privacy-policy'
    }
  ];

  socialNetworks = [
    {
      title: 'Facebook',
      href: 'http://facebook.com',
      icon: 'icon-facebook'
    },
    {
      title: 'Youtube',
      href: 'http://youtube.com',
      icon: 'icon-youtube'
    },
    {
      title: 'Twitter',
      href: 'http://twitter.com',
      icon: 'icon-twitter'
    },
    {
      title: 'Linkedin',
      href: 'http://linkedin.com',
      icon: 'icon-linkedin'
    },
    {
      title: 'Instagram',
      href: 'http://instagram.com',
      icon: 'icon-instagram'
    },
  ];

  constructor() {
    this.year = new Date().getFullYear();
  }
}
