<header>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <div class="d-flex justify-content-md-between justify-content-xl-center align-items-center w-100 gap-2">
        <a routerLink="/"><img src="assets/images/logo.svg" class="img-fluid"
                                                  alt="logo"></a>
        <a class="link semi-bold" routerLink="/">{{PROJECT_TITLE}}</a>
        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" aria-controls="menu-toggler"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse justify-content-end" [collapse]="isCollapsed">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a [href]="eServicesUrl" class="nav-link">{{'menuItems.serviceProviders'| translate}} </a>
          </li>
          <li class="nav-item">
            <a routerLink="payment-processors" class="nav-link">{{'menuItems.paymentProcessors'| translate}} </a>
          </li>
          <li class="nav-item">
            <a routerLink="faq" class="nav-link">{{'menuItems.faq'| translate}}</a>
          </li>
          <li class="nav-item">
            <a routerLink="about" class="nav-link">{{'menuItems.about'| translate}}</a>
          </li>
          <li class="nav-item">
            <a (click)="scrollToFooter()" class="nav-link">{{'menuItems.contacts'| translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
