import { TransactionTypesEnum } from '@shared/models/transaction-types.enum';


export interface TransactionLimit {
  currency: string;
  maxAmount: number;
  minAmount: number;
}

export class PaymentProcessor {
  id?: string;
  processingTransactionType?: TransactionTypesEnum;
  name?: string;
  displayName?: string;
  description?: string;
  channel?: string;
  redirectUrl?: string;
  commission = 0;
  website?: string;
  logo?: string;
  transactionLimits?: TransactionLimit[];
}
