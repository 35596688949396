import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  languages: any;
  currentLanguage = '';

  constructor(
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.languages = this.translate.getLangs().map((e) => ({text: e, id: e}));
    this.currentLanguage = this.translate.currentLang;
  }

  selectLanguage(evt): void {
    this.currentLanguage = evt?.target.innerText;
    this.translate.use(evt?.target.innerText);
  }

}
