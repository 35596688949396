import { Invoice } from './invoice.model';
import { Status } from './status.enum';


export class PaymentResultModel {
  created?: string;
  completed?: string;
  status?: Status;
  invoiceDetails?: Invoice;
  transactionDetails?: {
    number: string,
    accountReference: string,
    commission: number,
    totalAmount: number,
    completed: string,
    created: string,
    paymentProcessor: string,
    rrn: string
  };
  receiptCreated?: string;
}
