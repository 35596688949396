import { Invoice, Status, StatusConfig } from '../../models';
import { CommonService } from '../../services';
import { Component, Input } from '@angular/core';
import { STATUS_MAP } from '@shared/constants';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent {
  status: Status | undefined;
  statusConfig: StatusConfig | undefined;

  @Input()
  invoice?: Invoice | any;

  @Input() set invoiceStatus(status: Status | undefined) {
    this.statusConfig = STATUS_MAP.get(status) as StatusConfig;
  }

  get canPay(): boolean {
    return this.invoiceStatus === Status.Active;
  }

  constructor(
    private commonService: CommonService,
  ) {
  }

  downloadPdf(type: 'receipt' | 'invoice'): void {
    this.commonService.downloadPdf(this.invoice?.number, type);
  }
}
