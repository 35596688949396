import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: (): any =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'about',
    loadChildren: (): any =>
      import('./pages/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: (): any =>
      import('./pages/terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule,
      ),
  },
  {
    path: 'faq',
    loadChildren: (): any =>
      import('./pages/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'pay-for-service',
    loadChildren: (): any =>
      import('./pages/pay-for-service/pay-for-service.module').then(
        (m) => m.PayForServiceModule,
      ),
  },
  {
    path: 'verify',
    loadChildren: (): any =>
      import('./pages/pay-for-service/pay-for-service.module').then(
        (m) => m.PayForServiceModule,
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: (): any =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule,
      ),
  },
  {
    path: 'service-providers',
    loadChildren: (): any =>
      import('./pages/service-providers/service-providers.module').then(
        (m) => m.ServiceProvidersModule,
      ),
  },
  {
    path: 'payment-processors',
    loadChildren: (): any =>
      import('./pages/payment-processors/payment-processors.module').then(
        (m) => m.PaymentProcessorsModule,
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule,
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
