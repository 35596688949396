import { PaymentChannels, Status, StatusConfig } from '@shared/models';

export const PAYMENT_CHANNELS = [
  {
    name: 'Visa / Mastercard',
    channel: PaymentChannels.Card,
    logoCss: 'icon-visa',
    description: 'Multinational payment standard',
    imgPath: 'assets/images/icons/visa_master.svg',
  },
  {
    name: 'Mobile Money',
    channel: PaymentChannels.Mobile,
    logoCss: 'icon-mobile',
    description: 'The currency is tied to a mobile operator',
  },
  {
    name: 'Online Banking',
    channel: PaymentChannels.OnlineBanking,
    logoCss: 'icon-global',
    description: 'Provided by banks',
  },
  {
    name: 'Cash',
    channel: PaymentChannels.Cash,
    logoCss: 'icon-cash',
    description: 'Pay with cash at any participant payment processor',
  },
  {
    name: PaymentChannels.Wallet,
    channel: 'Wallet',
    logoCss: 'icon-digital-wallet',
    description: 'Pay online with your digital  wallet',
  },
];

export const PAYMENT_CHANNELS_HOME = [
  {
    name: 'Visa / Mastercard',
    imgPath: './assets/images/Method-visa.png',
    description:
      'Select your preferred payment processor and pay online with your Visa/Mastercard card',
  },
  {
    name: 'Online Banking',
    imgPath: './assets/images/Method-online-bank.png',
    description:
      'Select your preferred payment processor and pay online with your online banking account',
  },
  {
    name: 'Mobile Money',
    imgPath: './assets/images/Method-mobile.png',
    description:
      'Select your preferred payment processor and pay online with your mobile money account',
  },
  {
    name: 'Cash',
    imgPath: './assets/images/Method-cash.png',
    description: 'Select your preferred payment processor and pay with cash',
  },
  {
    name: 'Digital Wallet',
    imgPath: './assets/images/Method-digital-wallet.png',
    description:
      'Select your preferred payment processor and pay online with your digital wallet',
  },
];

export const PROJECT_TITLE = 'ZamPay - Government Payment Gateway';

export const LAST_PAYMENT_ATTEMPT_MAP = new Map<
  Status | undefined,
  StatusConfig
>([
  [
    Status.Active,
    {
      label: 'paymentStatus.notAvailable',
      color: '',
    },
  ],
  [
    Status.InProgress,
    {
      label: 'InProgress',
      color: 'text-secondary',
    },
  ],
  [
    Status.Paid,
    {
      label: 'paymentStatus.notAvailable',
      color: '',
    },
  ],
  [
    Status.Expired,
    {
      label: 'paymentStatus.notAvailable',
      color: '',
    },
  ],
  [
    Status.Canceled,
    {
      label: 'paymentStatus.notAvailable',
      color: '',
    },
  ],
  [
    Status.Failed,
    {
      label: 'Failed',
      color: 'text-warning',
    },
  ],
  [
    Status.NotFound,
    {
      label: 'paymentStatus.notAvailable',
      color: '',
    },
  ],
]);

export const STATUS_MAP = new Map<Status | undefined, StatusConfig>([
  [
    Status.Active,
    {
      label: 'paymentStatus.notPaid',
      color: 'text-secondary',
    },
  ],
  [
    Status.InProgress,
    {
      label: 'paymentStatus.notPaid',
      color: 'text-secondary',
    },
  ],
  [
    Status.Paid,
    {
      label: 'paymentStatus.paid',
      color: 'text-primary',
    },
  ],
  [
    Status.Expired,
    {
      label: 'paymentStatus.expired',
      color: 'text-warning',
    },
  ],
  [
    Status.Canceled,
    {
      label: 'paymentStatus.canceled',
      color: 'text-warning',
    },
  ],
  [
    Status.Failed,
    {
      label: 'paymentStatus.notPaid',
      color: 'text-secondary',
    },
  ],
  [
    Status.NotFound,
    {
      label: 'paymentStatus.notFound',
      color: 'text-secondary',
    },
  ],
]);
