<footer>
  <div class="bg-footer container-fluid pt-5">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3 pt-0 p-5">
        <div class="row justify-content-center px-3">
          <div class="col-12 d-flex justify-content-center logo">
            <img alt="logo" class="img-fluid" src="/assets/images/logo.svg"/>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <p class="custom-fs-3 semi-bold text-white mt-2 text-center">Government of the Republic of Zambia</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 pb-4 px-5 px-md-4 px-lg-4">
        <div class="row">
          <div class="col-12">
            <ng-container [ngTemplateOutlet]="sectionTitle"
                          [ngTemplateOutletContext]="{title:'footer.usefulLinks'}">
            </ng-container>
          </div>
          <div class="col-12">
            <ng-container *ngFor="let link of usefulLinks">
              <a [routerLink]="link.href" class="link">
                <p class="p4-400 lh-sm">{{link.title| translate}}</p>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 pb-4 px-5 px-md-4 px-lg-4">
        <div class="row">
          <div class="col-12">
            <ng-container [ngTemplateOutlet]="sectionTitle"
                          [ngTemplateOutletContext]="{title:'footer.socialNetworks'}">
            </ng-container>
          </div>
          <div class="col-12">
            <ng-container *ngFor="let link of socialNetworks">
              <div class="row mb-3">
                <div class="col-auto pe-0 social-network-icon d-flex justify-content-center">
                  <i class="{{link.icon}} custom-fs-2 text-white"></i>
                </div>
                <div class="col-10 d-flex align-items-center">
                  <a [href]="link.href" class="link p4-400">
                    <p class="p4-400 lh-1 m-0">{{link.title| translate}}</p>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 pb-4 px-5 px-md-4 px-lg-4">
        <div class="row">
          <div class="col-12">
            <ng-container [ngTemplateOutlet]="sectionTitle"
                          [ngTemplateOutletContext]="{title:'footer.contacts'}">
            </ng-container>
          </div>
          <div class="col-12">
            <p class="mb-0 custom-fs-3 text-white">{{'footer.phone'| translate}}:</p>
            <p class="text-white custom-fs-1">
              +260 <a href="tel:+260211428600" class="text-secondary custom-fs-1">211 428 600</a>
            </p>
          </div>
          <div class="col-12">
            <p class="mb-0 custom-fs-3 text-white">{{'footer.email'| translate}}:</p>
            <a href="mailto:info@eservices.gov.zm" class="link">info@eservices.gov.zm</a>
            <p class="mb-0 custom-fs-3 text-grey-2">{{'footer.shortCode'| translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-wrap bg-brand-primary d-flex justify-content-center">
    <div class="copyright d-flex align-items-center my-1">
      <span class="text-white fw-normal text-center custom-fs-5">
        {{'footer.allRightsReserved'| translate:{
        year: year,
        version: version
      } }}</span>
    </div>
  </div>
</footer>

<ng-template #sectionTitle let-title='title'>
  <p class="text-white p4-300 m-0">{{title| translate}}</p>
  <hr class="text-muted mt-2"/>
</ng-template>
