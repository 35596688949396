<div *ngIf="!!languages?.length" class="btn-group" dropdown>
  <button id="button-basic" dropdownToggle type="button" class="btn btn-transparent px-0"
          aria-controls="language-selector" data-testid="language-selector">
    {{currentLanguage}} <i class="fas fa-caret-down"></i>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" (click)="selectLanguage($event)"
      role="menu" aria-labelledby="button-basic">
    <li *ngFor="let lang of languages" role="menuitem"><a class="dropdown-item" href="#">{{lang.text}}</a></li>
  </ul>
</div>
