import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import { LoaderService } from '@shared/services/loader.service';
import { Commission, Invoice, PaymentProcessor, PaymentResultModel, ReceiptModel, ServiceProvider, Statistics } from '../models';
import { AppConfig } from './setting.service';

@Injectable({providedIn: 'root'})
export class DataService {
  constructor(private http: HttpClient, private loaderService: LoaderService) {
  }

  executeTransaction(invoice: Invoice, processorName: string = '', accountReference = ''): Observable<any> {
    const params = {
      invoiceNumber: invoice.number,
      accountReference,
      paymentProcessor: processorName
    };
    return this.http.post(this.url(`payments/execute`), params, {responseType: 'text'});
  }

  getInvoice(invoiceNumber: string): Observable<Invoice> {
    const encodedInvoice = encodeURIComponent(invoiceNumber);
    const req = this.http.get<Invoice>(this.url(`payments/${encodedInvoice ?? invoiceNumber}/invoice`));
    return this.withLoader(req);
  }

  getPaymentStatus(invoiceNumber: string): Observable<Record<'status', string>> {
    const encodedInvoice = encodeURIComponent(invoiceNumber);
    return this.http.get<Record<'status', string>>(this.url(`payments/${encodedInvoice ?? invoiceNumber}/status`));
  }

  getTimeout(): Observable<{ timeout: number }> {
    const req = this.http.get<{ timeout: number }>(this.url(`payments/timeout`));
    return this.withLoader(req);
  }

  getActivePaymentProcessors(): Observable<PaymentProcessor[]> {
    const req = this.http.get<PaymentProcessor[]>(
      this.url(`payments/paymentProcessors`)
    );
    return this.withLoader(req);
  }

  getCommissions(amount: string | number, currency: string): Observable<Commission[]> {
    const req = this.http.get<Commission[]>(
      this.url(`payments/commissions?amount=${amount}&currency=${currency}`)
    );
    return this.withLoader(req);
  }

  getPaymentDetails(invoiceNumber: string): Observable<PaymentResultModel> {
    const encodedInvoice = encodeURIComponent(invoiceNumber);
    const req = this.http.get<PaymentResultModel>(this.url(`payments/${encodedInvoice ?? invoiceNumber}`));
    return this.withLoader(req);
  }

  getReceipt(invoiceNumber: string): Observable<ReceiptModel> {
    const encodedInvoice = encodeURIComponent(invoiceNumber);
    const req = this.http.get<ReceiptModel>(this.url(`payments/${encodedInvoice ?? invoiceNumber}/receipt`));
    return this.withLoader(req);
  }

  downloadPaymentPdf(invoiceNo: string, type: 'invoice' | 'receipt' = 'receipt'): Observable<any> {
    return this.http.get(this.url(`pdf?invoiceNumber=${invoiceNo}&documentType=${type}`, AppConfig.settings.pdfServiceUrl), {responseType: 'arraybuffer'});
  }

  getStatistics(): Observable<Statistics> {
    const req = this.http.get<Statistics>(this.url(`payments/statistics`));
    return this.withLoader(req);
  }

  getServiceProviders(): Observable<any> {
    const req = this.http.get<ServiceProvider[]>(this.url(`payments/serviceProviders`));
    return this.withLoader(req);
  }

  getAboutData(): Observable<any> {
    const req = this.http.get('assets/data/about.html', {responseType: 'text'});
    return this.withLoader(req);
  }

  getHelpContent(): Observable<any> {
    const req = this.http.get('assets/data/help_data.json');
    return this.withLoader(req);
  }

  getFaqData(): Observable<any> {
    const req = this.http.get('assets/data/faq_data.json');
    return this.withLoader(req);
  }

  url(enpoint: string, apiUrl = AppConfig.settings.apiUrl || ''): string {
    const prefix = apiUrl[apiUrl.length - 1] === '/' ? '' : '/';
    return apiUrl.concat(prefix, enpoint);
  }

  private handleNotFound(req: Observable<any>): Observable<any> {
    req = req.pipe(
      catchError((err) => {
        if (err.status === 404) {
          return of([]);
        } else if (err.status === 424) {
          return of([]);
        }
        return throwError(err);
      }),
      map((res) => {
        if (res === null) {
          return [];
        }
        return res;
      })
    );

    return of('').pipe(switchMap(() => req));
  }

  private withLoader(req: Observable<any>): Observable<any> {
    this.loaderService.showLoader();
    return req.pipe(finalize(() => this.loaderService.hideLoader()));
  }
}
