import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LoaderService } from '@shared/services/loader.service';

@UntilDestroy()
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  almostDone = false;
  showLoader = false;

  constructor(public loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.loaderService.loaderStatus$.pipe(untilDestroyed(this)).subscribe((showLoader) => {
      if (!showLoader) {
        this.almostDone = true;
      }

      setTimeout(() => {
        this.showLoader = showLoader;
        this.almostDone = false;
      }, this.almostDone ? 1000 : 0);
    });
  }
}
