import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { HttpClientModule } from '@angular/common/http';
import { NgxCaptchaModule } from 'ngx-captcha';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { InvoiceNotFoundComponent } from './components/invoice-not-found/invoice-not-found.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { InputsModule, WavesModule, ButtonsModule, InputUtilitiesModule } from 'angular-bootstrap-md';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { StepperComponent } from './components/stepper/stepper.component';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

const DECLARATIONS = [
  PaymentStatusComponent,
  InvoiceNotFoundComponent,
  SortByPipe,
  CustomDatePipe,
  FileUploadComponent,
  LoaderComponent,
  StepperComponent,
];

@NgModule({
  declarations: [ ...DECLARATIONS ],
  imports: [
    TooltipModule.forRoot(),
    FormsModule,
    CommonModule,
    HttpClientModule,
    CollapseModule.forRoot(),
    NgxCaptchaModule,
    FileUploadModule,
    NgxMaskModule.forRoot(maskConfig),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    ImgFallbackModule,
    NgxExtendedPdfViewerModule,
    TranslateModule,
    NgxCaptchaModule,
    InputsModule, WavesModule, ButtonsModule,
    InputUtilitiesModule,
    RouterModule
  ],
  exports: [
    ...DECLARATIONS,
    FormsModule,
    TooltipModule,
    CollapseModule,
    NgxCaptchaModule,
    BsDropdownModule,
    AccordionModule,
    NgxMaskModule,
    SortByPipe,
    ImgFallbackModule,
    NgxExtendedPdfViewerModule,
    TranslateModule,
    NgxCaptchaModule,
    InputsModule, WavesModule, ButtonsModule, InputUtilitiesModule
  ],
  providers: [ SortByPipe, DatePipe ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ LoaderService ]
    };
  }
}

