import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-not-found',
  templateUrl: './invoice-not-found.component.html',
  styleUrls: ['./invoice-not-found.component.scss']
})
export class InvoiceNotFoundComponent implements OnInit {

  @Input()
  invoiceNo: string = '';
  invoice: string = ''
  constructor() { }

  ngOnInit() {
    this.invoice = this.invoiceNo;
  }

}
